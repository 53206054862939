export default class Facility {
    facilityID: string
    facilityName: string
    facilityCode: string
    brandID: string
    zip: string | null
    country: string | null
    state: string | null
    city: string | null
    address: string | null
    phone: string | null
    longitude: string | null | ""
    latitude: string | null | ""
    range: number | null | ""
    picture: string | null
    isDeleted: boolean
    brandName : string

    constructor(facilityID: string, facilityName: string,facilityCode: string, brandID: string,
        zip: string | null, country: string | null, state: string | null, city: string | null, address: string | null, phone: string | null,
        longitude: string | null | "", latitude: string | null | "", range: number | null | "", picture: string | null, isDeleted: number, brandName: string = '') {
        this.facilityID = facilityID
        this.facilityName = facilityName
        this.facilityCode = facilityCode
        this.brandID = brandID
        this.zip = zip
        this.country = country
        this.state = state
        this.city = city
        this.address = address
        this.phone = phone
        this.longitude = (longitude !== null && longitude !== "") ? Number.parseFloat(longitude).toFixed(6) : longitude
        this.latitude = (latitude !== null && latitude !== "") ? Number.parseFloat(latitude).toFixed(6) : latitude
        this.range = range
        this.picture = picture
        this.isDeleted = isDeleted === 1
        this.brandName = brandName
    }
}